<template>
  <section class="settings mt-3">
    <b-row>
      <b-col cols="12">
        <d-checkbox
          label="admin.clubs.settings.book-registering.title"
          v-model="bookingCompleteRegistrationEnabled"
          :disabled="! canAccessToSettings">
            <template #disabled-message>
              {{ $t("admin.clubs.settings.book-registering.bad-app-version", { version: config.appVersion }) }} 
            </template>
        </d-checkbox>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col cols="12">
        <d-checkbox
          label="admin.clubs.settings.match.title"
          v-model="matchEnabled"
          :disabled="! canAccessToSettings">
            <template #disabled-message>
              {{ $t("admin.clubs.settings.book-registering.bad-app-version", { version: config.appVersion }) }} 
            </template>
        </d-checkbox>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col cols="3" class="mt-2 mb-2">
        <button class="d-btn-small btn text-center d-btn-primary btn-full-width" @click="save">
          {{ $t('general.actions.validate') }}
        </button>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { putClub } from '@/api/services/club/club.api';
import DCheckbox from '@/components/form/DCheckbox.vue';

export default {
  components: {
    DCheckbox,
  },
  props: {
    club: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      bookingCompleteRegistrationEnabled: true,
      matchEnabled: true,
    };
  },
  watch: {
    club: {
      handler() {
        this.bookingCompleteRegistrationEnabled = this.club.bookingCompleteRegistrationEnabled;
        this.matchEnabled = this.club.matchEnabled;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    canAccessToSettings() {
      const versionNeeded = "6.3";
      const [majorNeeded, minorNeeded] = versionNeeded.split('.');
      const [major, minor] = this.config.appVersion.split('.');

      return major > majorNeeded || (major === majorNeeded && minor >= minorNeeded);
    },
  },
  methods: {
    async save () {
      const response = await putClub(this.club.id, {
        matchEnabled: this.matchEnabled,
        bookingCompleteRegistrationEnabled: this.bookingCompleteRegistrationEnabled
      });
      this.$vs.notify({
        time: 10000,
        title: this.$t("admin.clubs.settings.success_save.title"),
        text: this.$t("admin.clubs.settings.success_save.message"),
        color: "success",
        position: "top-right",
      });
    },
  },
};
</script>
