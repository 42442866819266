<template>
  <div class="checkbox-container mb-3 d-flex flex-row-reverse align-items-center justify-content-end cursor-pointer">
    <label class="m-0" :class="{ 'disabled': disabled }" :for="generatedId">
      <span>{{ $t(label) }}</span>
      <span v-if="disabled" class="disabled-message">
        <slot name="disabled-message" />
      </span>
    </label>
    <input
      type="checkbox"
      :id="generatedId"
      :value="value"
      :checked="value"
      class="mx-2"
      :disabled="disabled"
      @change="changeValue"/>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    name: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    generatedId () {
      return `checkbox-${this._uid}`;
    },
  },
  methods: {
    changeValue () {
      this.$emit('input', ! this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/checkbox/_checkbox";

.disabled-message {
  display: block;
  width: 100%;
  position: absolute;
  padding-left: 10px;
  font-size: 0.8rem;
  color: #FF0101;
  border-left: 1px solid;
}
</style>